<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.faultCode')"
    id="faultCode"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
      </template>
      <div class="view-form">
        <div class="text item">
          <label>{{ $t("i18n.elevatorNo") }}</label>
          <span>{{ eleDetail.number }}</span>
        </div>
        <div class="text item">
          <label>{{ $t("i18n.projectName") }}</label>
          <span>{{ eleDetail.projectName }}</span>
        </div>
        <div class="text item">
          <label>{{ $t("i18n.controllerType") }}</label>
          <span style="width: auto">{{ eleDetail.controllerType }}</span>
        </div>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.faultCode") }}</span>
      </template>
      <div class="text item">
        <div class="text-right" style="margin-right: 80px">
          <el-button type="primary" round @click="saveAll">
            {{ $t("i18n.saveAll") }}
          </el-button>
        </div>
        <el-checkbox-group v-model="checkboxGroup1" class="m-t">
          <div class="col-item" v-for="(item, index) in faultCode" :key="index">
            <el-checkbox :label="item.faultCode" border>
              {{ item.faultCode }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "FaultCode",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      checkboxGroup1: [],
      eleDetail: "",
      editList: [], // 编辑克隆数据
      faultCode: [],
    });

    const init = async () => {
      let { data } = await proxy.$api.system.getElevatorDetail(route.query.id);
      state.eleDetail = data;
      getCodeByNumber();
    };

    const getCodeByNumber = async () => {
      let { data } = await proxy.$api.system.getCodeByNumber(
        state.eleDetail.number
      );
      state.faultCode = data;
      data.map((item) => {
        if (item.pushable) {
          state.checkboxGroup1.push(item.faultCode);
          state.editList.push(item.faultCode);
        }
      });
    };

    const saveAll = () => {
      // 保存全部
      var faultCodeList = [],
        diffList = [];
      state.editList.map((item) => {
        if (state.checkboxGroup1.indexOf(item) == -1) {
          diffList.push(item);
        }
      });
      state.checkboxGroup1.map((item) => {
        // 选中的故障
        let obj = {
          faultCode: item,
          pushable: true,
        };
        faultCodeList.push(obj);
      });
      diffList.map((item) => {
        // 取消选中故障
        let obj = {
          faultCode: item,
          pushable: false,
        };
        faultCodeList.push(obj);
      });
      var faultCodeListCtrlTypePushDto = {
        elevatorNumber: state.eleDetail.number,
        faultCodeList: faultCodeList,
      };
      proxy.$api.system
        .setMulFaultCodeByEle(
          state.eleDetail.number,
          faultCodeListCtrlTypePushDto
        )
        .then((res) => {
          proxy.$defined.tip(res.description, "success");
          getCodeByNumber();
        });
    };

    init();

    return {
      ...toRefs(state),
      saveAll,
    };
  },
};
</script>

<style lang="scss">
#faultCode {
  .col-item {
    width: 100px;
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
